<template>
  <div class="center_main">
    <div class="title">{{ $t("hj14") }}</div>
    <div class="main_box">
      <van-loading
        type="spinner"
        color="#1989fa"
        class="loading"
        v-show="loadingShow"
      />
      <li v-for="(item, index) in data" :key="index">
        <div>
          <div class="p1">{{ item.title }}</div>
          <div class="p2">{{ item.content }}</div>
        </div>
      </li>
    </div>
  </div>
</template>

<script>
import { resetDictionaryStatus } from '../http/api'
export default {
  data () {
    return {
      data: [],
      loadingShow: true
    }
  },
  created () {
    this.dataInit()
  },
  methods: {
    dataInit () {
      resetDictionaryStatus().then((res) => {
        this.loadingShow = false
        this.data = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.center_main {
  background: #122531;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 20px 20px 0 0;
  z-index: 2;
  .title {
    line-height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 1rem;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 400;
    font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
      sans-serif;
  }
  .main_box {
    li {
      width: 90%;
      background: linear-gradient(180deg, #184355 0%, #0c2733 100%);
      box-shadow: 2px 6px 18px #0000001a;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 1rem;
      margin: 5%;
      gap: 16px;
      list-style-type: none;
      display: flex;
      .img_box {
        width: 4rem;
        height: 4rem;
        img {
          width: 4rem;
          height: 4rem;
        }
      }
      .p1 {
        color: #fff;
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
      .p2 {
        color: #82899a;
        font-size: 0.8rem;
      }
    }
  }
  .loading {
    text-align: center;
    margin-top: 20%;
  }
}
</style>