<template>
  <div class="center_main">
    <div class="header_ico" @click="closeShow">
      <img src="/ico/close.png" />
    </div>
    <div class="main">
      <div class="size2">{{ $t("hj15") }}</div>
      <div class="size3">{{ activity.awards }}USDT</div>
      <div class="size2">{{ $t("hj16") }}</div>
      <div class="size2">{{ activity.act_name }}</div>
      <div class="size2">{{ activity.progress_name }}</div>
      <div class="border">{{ $t("hj17") }}</div>
      <div class="justify" style="line-height: 1.2rem; margin-bottom: 1rem">
        {{ activity.act_rule }}
      </div>
      <div class="detail_box">
        <div class="border2">
          <div>{{ $t("hj18") }}</div>
          <span>{{ activity.end_time }}</span>
        </div>

        <div class="border2">
          <div>{{ $t("hj19") }}</div>
          <div>{{ activity.awards }}(USDT)</div>
        </div>

        <div class="border2">
          <div>{{ $t("hj20") }}</div>
          <div>{{ activity.user_balance }}(USDT)</div>
        </div>
      </div>
      <div style="margin: 2rem 0 1rem">{{ $t("hj21") }}</div>
      <div class="justify">{{ activity.act_rule }}</div>

      <div class="border3">
        <div>{{ $t("hj22") }}(USDT)</div>
        <div>{{ activity.total_eth_profit }}</div>
        <div>{{ $t("hj23") }}(USDT): {{ activity.awards }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { act_detail } from '../http/api'
export default {
  data () {
    return {
      nowIndex: 1,
      activity: ''
    }
  },
  created () {
    this.dataInit()
  },
  methods: {
    closeShow () {
      this.$router.push('home')
    },
    dataInit () {
      act_detail(this.$route.query.id).then((res) => {
        this.activity = res.data
        console.log(this.activity)
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.center_main {
  background: #122531;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  color: #fff;
  .header_ico {
    img {
      width: 1rem;
      margin: 1rem;
    }
  }
  .main {
    overflow: auto;
    text-align: center;
    background: #122531;
    padding: 1rem 0 2rem 0;
    .detail_box {
      display: flex;
      justify-content: space-around;
    }
    .border {
      border-radius: 1rem;
      width: 5rem;
      line-height: 2rem;
      margin: 0.5rem auto;
      border: 1px solid #fff;
    }
    .size2 {
      font-size: 1.2rem;
      line-height: 2rem;
    }
    .size3 {
      font-size: 1.8rem;
      line-height: 2rem;
    }
    .justify {
      text-align: justify;
      padding: 0 2rem;
    }
    .border2 {
      border-radius: 0.4rem;
      height: 5rem;
      font-size: 1rem;
      margin: 0 auto;
      border: 1px solid #fff;
      padding: 1rem 0;
      div {
        height: 1.5rem;
      }
    }
    .border3 {
      border-radius: 0.4rem;
      height: 5rem;
      font-size: 1rem;
      margin: 2rem 2rem 0 2rem;
      border: 1px solid #fff;
      padding: 0.5rem;
      text-align: start;
    }
  }
}
</style>