import Vue from 'vue';
import VueRouter from 'vue-router';
//首页
import Home from '../pages/home/index.vue'; /* 首页 */
import use from '../pages/user.vue'; /* 首页 */
import Pledge from '../pages/pledge/index.vue'; /*质押*/
import pledgeDetail from '../pages/pledge/detail.vue'; /*质押详情*/
import activityDea from '../pages/activityDea'; /*质押详情*/

Vue.use(VueRouter);
const routes = [
  { path: '/', redirect: '/Home', component: Home, meta: { title: '首页' } },
  { path: '/Home', name: 'home', component: Home, meta: { title: '首页' } },
  {
    path: '/use',
    name: 'use',
    component: use,
    meta: { title: '用户详情' }
  },
  {
    path: '/pledge',
    name: 'pledge',
    component: Pledge,
    meta: { title: '质押' }
  },
  {
    path: '/pledgeDetail',
    name: 'pledgeDetail',
    component: pledgeDetail,
    meta: { title: '质押详情' }
  },
  {
    path: '/activityDea',
    name: 'activityDea',
    component: activityDea,
    meta: { title: '活动详情' }
  },
  {
    path: '/activityDea',
    name: 'activityDea',
    component: activityDea,
    meta: { title: '活动详情' }
  }
];

//生成路由实例
const router = new VueRouter({
  routes
});
router.beforeEach((to, from, next) => {
  //修改标题路由配置加上这个
  document.title = to.matched[0].meta.title;
  next();
});

export default router;
