<template>
  <div class="center_main">
    <div class="title">{{ $t("hj46") }}</div>
    <ul>
      <li @click="changeLang('zh_TW')">
        <img class="img" src="/ico/tw.png" />繁體
      </li>
      <li @click="changeLang('en')">
        <img class="img" src="/ico/us.png" />English
      </li>
      <li @click="changeLang('zh_CN')">
        <img class="img" src="/ico/cn.png" />简体
      </li>
      <li @click="changeLang('ja')">
        <img class="img" src="/ico/jp.png" />日本語
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    changeLang (value) {
      this.$i18n.locale = value;
      window.localStorage.setItem('language', value);
    }
  }
}
</script>

<style lang="scss" scoped>
.center_main {
  background: #122531;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 20px 20px 0 0;
  z-index: 2;
  .title {
    line-height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 400;
    font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
      sans-serif;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px 20px;
    gap: 16px;
    li {
      width: 100%;
      background: linear-gradient(180deg, #184355 0%, #0c2733 100%);
      box-shadow: 2px 6px 18px #0000001a;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px;
      gap: 8px;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.5rem;
      letter-spacing: 0.48px;
      color: #fff;
    }
    .img {
      width: 1.2rem;
    }
  }
}
</style>