/* eslint-disable */
export default {
  hj1: '餘額',
  hj2: '活動獎勵',
  hj3: '鏈上餘額',
  hj4: '查看獎勵',
  hj5: '提現',
  hj6: '兌換',
  hj7: '全部',
  hj8: '活動獎勵',
  hj9: '質押獎勵',
  hj10: '提現記錄',
  hj11: '暫無數據',
  hj12: '獎勵金額',
  hj13: '提現金額',
  hj14: '幫助中心',
  hj15: '您將接受高達',
  hj16: '獎勵',
  hj17: '規則',
  hj18: '結束',
  hj19: '獎勵標準',
  hj20: '用戶金額',
  hj21: '説明',
  hj22: '總獎勵',
  hj23: '用戶獎勵',
  hj24: '參與活動',
  hj25: '領取',
  hj26: '質押金額',
  hj27: '確認',
  hj28: '取消',
  hj31: '質押',
  hj32: '開始質押',
  hj33: '現在前往',
  hj34: '我知道了',
  hj35: '收益率',
  hj36: '已質押額',
  hj37: '開始',
  hj38: '賬戶',
  hj39: '年化利率',
  hj40: '收益記錄',
  hj41: '幫助中心',
  hj42: '介紹質押',
  hj43: '活動',
  hj44: '選擇語言',
  hj45: '客服中心',
  hj46: '選擇語言',
  hj47: '開始質押',
  hj48: '請輸入質押金額',
  hj49: '質押',
  hj50: '參加成功',
  hj51: '確定',
  hj52: '發送',
  hj53: '複製',

  hj54: '連結碼',
  hj55: '複製邀請碼',
  hj56: '接受USDT連結碼',
  hj57: '發送USDT至',
  hj58: '輸入USDT數量',
  hj59: '最小需求金額',
  hj60: '確定參加',
  hj61: '活動鎖定時間',
  hj62: '領取獲利金額',
  hj63: '未到解鎖時間',
  hj64: '活動獎勵（USDT）已綁定',

  hj65: '本次活動獎勵金額'
};
