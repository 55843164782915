<template>
  <div class="center_main">
    <div class="header_ico" @click="closeShow">
      <img src="/ico/close.png" />
    </div>

    <div class="mian_box" v-show="showPage == 1">
      <div class="top_box">
        <img class="title_img" src="/ico/use.svg" />
        <div class="money">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            class="iconify iconify--ph"
            width="1em"
            height="1em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 256 256"
            data-icon="ph:wallet-light"
          >
            <path
              d="M216 74H56a10 10 0 0 1 0-20h136a6 6 0 0 0 0-12H56a22.025 22.025 0 0 0-22 22v128a22.025 22.025 0 0 0 22 22h160a14.016 14.016 0 0 0 14-14V88a14.016 14.016 0 0 0-14-14zm2 126a2.003 2.003 0 0 1-2 2H56a10.012 10.012 0 0 1-10-10V83.593A21.857 21.857 0 0 0 56 86h160a2.003 2.003 0 0 1 2 2zm-28-56a10 10 0 1 1-10-10a10 10 0 0 1 10 10z"
              fill="currentColor"
            ></path>
          </svg>
          {{ $t("hj1") }} (USDT)
        </div>
        <!-- <div style="font-size: 2rem; font-weight: 600; margin: 1rem 0">
          {{ useData.usdt_balance }}
        </div> -->
        <div class="ico_box">
          <div class="left_box">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              class="iconify iconify--basil"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
              data-icon="basil:exchange-outline"
            >
              <path
                fill="currentColor"
                d="M7.53 6.53a.75.75 0 0 0-1.06-1.06l-2 2a.75.75 0 0 0 0 1.06l2 2a.75.75 0 1 0 1.06-1.06l-.72-.72H17a.75.75 0 0 0 0-1.5H6.81zm8.94 6.94a.75.75 0 0 0 0 1.06l.72.72H7a.75.75 0 0 0 0 1.5h10.19l-.72.72a.75.75 0 1 0 1.06 1.06l2-2a.75.75 0 0 0 0-1.06l-2-2a.75.75 0 0 0-1.06 0"
              ></path>
            </svg>
            {{ $t("hj1") }} (USDT)
          </div>

          <div class="right_box">${{ plat_balance }}</div>
        </div>

        <div class="ico_box">
          <div class="left_box">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M15.0801 8.20398L17.4944 9.62878L11.6508 13.0792L5.71042 9.57198L8.15331 8.12959L7.06775 6.92719L3.71739 8.90558C3.59888 8.97553 3.50037 9.07659 3.43184 9.19853C3.36331 9.32048 3.32719 9.45897 3.32715 9.59998V18.4C3.32706 18.5411 3.36311 18.6798 3.43165 18.8019C3.50019 18.924 3.59877 19.0252 3.71739 19.0952L11.1699 23.4952C11.2869 23.5642 11.4193 23.6005 11.554 23.6005C11.6887 23.6005 11.8211 23.5642 11.938 23.4952L19.3906 19.0952C19.5092 19.0252 19.6078 18.924 19.6763 18.8019C19.7449 18.6798 19.7809 18.5411 19.7808 18.4V9.59998C19.7808 9.45897 19.7447 9.32048 19.6761 9.19853C19.6076 9.07659 19.5091 8.97553 19.3906 8.90558L16.1657 7.00158L15.0801 8.20398ZM4.87496 10.9216L10.8757 14.4648V21.4792L4.87496 17.936V10.9216ZM18.2315 17.936L12.4243 21.3648V14.464L18.2315 11.0352V17.9352V17.936Z"
                fill="#82899A"
              ></path>
              <path
                d="M8.78046 3.4344C8.63942 3.58528 8.56138 3.78737 8.56314 3.99712C8.5649 4.20688 8.64633 4.40753 8.78989 4.55586C8.93345 4.70418 9.12766 4.78832 9.33067 4.79014C9.53369 4.79196 9.72928 4.71133 9.87531 4.5656L10.8625 3.5456L10.8672 8.8008C10.8674 9.01298 10.9492 9.21637 11.0945 9.36625C11.2399 9.51613 11.4369 9.60021 11.6422 9.6C11.8476 9.59979 12.0445 9.5153 12.1895 9.36512C12.3346 9.21494 12.416 9.01138 12.4158 8.7992L12.4111 3.5432L13.3999 4.5656C13.4713 4.64201 13.5567 4.70296 13.6512 4.74488C13.7457 4.78681 13.8473 4.80888 13.9501 4.8098C14.0529 4.81073 14.1549 4.79048 14.25 4.75026C14.3452 4.71004 14.4316 4.65063 14.5043 4.57552C14.577 4.5004 14.6345 4.41108 14.6735 4.31277C14.7124 4.21445 14.732 4.10911 14.7311 4.00288C14.7302 3.89666 14.7088 3.79168 14.6683 3.69408C14.6277 3.59648 14.5687 3.5082 14.4947 3.4344L12.2524 1.1176C12.179 1.01696 12.0837 0.935666 11.9741 0.880343C11.8646 0.82502 11.7439 0.797239 11.622 0.799267C11.5001 0.801295 11.3804 0.833074 11.2726 0.892012C11.1648 0.95095 11.0721 1.03538 11.0019 1.1384L8.77969 3.4344H8.78046Z"
                fill="#82899A"
              ></path>
            </svg>
            {{ $t("hj2") }}(USDT)
          </div>

          <div class="right_box">{{ usdt_balance }}</div>
        </div>

        <div class="ico_box">
          <div class="left_box">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M15.0801 8.20398L17.4944 9.62878L11.6508 13.0792L5.71042 9.57198L8.15331 8.12959L7.06775 6.92719L3.71739 8.90558C3.59888 8.97553 3.50037 9.07659 3.43184 9.19853C3.36331 9.32048 3.32719 9.45897 3.32715 9.59998V18.4C3.32706 18.5411 3.36311 18.6798 3.43165 18.8019C3.50019 18.924 3.59877 19.0252 3.71739 19.0952L11.1699 23.4952C11.2869 23.5642 11.4193 23.6005 11.554 23.6005C11.6887 23.6005 11.8211 23.5642 11.938 23.4952L19.3906 19.0952C19.5092 19.0252 19.6078 18.924 19.6763 18.8019C19.7449 18.6798 19.7809 18.5411 19.7808 18.4V9.59998C19.7808 9.45897 19.7447 9.32048 19.6761 9.19853C19.6076 9.07659 19.5091 8.97553 19.3906 8.90558L16.1657 7.00158L15.0801 8.20398ZM4.87496 10.9216L10.8757 14.4648V21.4792L4.87496 17.936V10.9216ZM18.2315 17.936L12.4243 21.3648V14.464L18.2315 11.0352V17.9352V17.936Z"
                fill="#82899A"
              ></path>
              <path
                d="M8.78046 3.4344C8.63942 3.58528 8.56138 3.78737 8.56314 3.99712C8.5649 4.20688 8.64633 4.40753 8.78989 4.55586C8.93345 4.70418 9.12766 4.78832 9.33067 4.79014C9.53369 4.79196 9.72928 4.71133 9.87531 4.5656L10.8625 3.5456L10.8672 8.8008C10.8674 9.01298 10.9492 9.21637 11.0945 9.36625C11.2399 9.51613 11.4369 9.60021 11.6422 9.6C11.8476 9.59979 12.0445 9.5153 12.1895 9.36512C12.3346 9.21494 12.416 9.01138 12.4158 8.7992L12.4111 3.5432L13.3999 4.5656C13.4713 4.64201 13.5567 4.70296 13.6512 4.74488C13.7457 4.78681 13.8473 4.80888 13.9501 4.8098C14.0529 4.81073 14.1549 4.79048 14.25 4.75026C14.3452 4.71004 14.4316 4.65063 14.5043 4.57552C14.577 4.5004 14.6345 4.41108 14.6735 4.31277C14.7124 4.21445 14.732 4.10911 14.7311 4.00288C14.7302 3.89666 14.7088 3.79168 14.6683 3.69408C14.6277 3.59648 14.5687 3.5082 14.4947 3.4344L12.2524 1.1176C12.179 1.01696 12.0837 0.935666 11.9741 0.880343C11.8646 0.82502 11.7439 0.797239 11.622 0.799267C11.5001 0.801295 11.3804 0.833074 11.2726 0.892012C11.1648 0.95095 11.0721 1.03538 11.0019 1.1384L8.77969 3.4344H8.78046Z"
                fill="#82899A"
              ></path>
            </svg>
            其他幣種({{ exchange_coin == "" ? "未持有" : exchange_coin }})
          </div>

          <div class="right_box">
            {{ exchange_coin_num }}
          </div>
        </div>

        <!-- <div class="ico_box">
          <div class="left_box">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              class="iconify iconify--ph"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 256 256"
              data-icon="ph:line-segments"
            >
              <path
                d="M238.627 33.372a32.037 32.037 0 0 0-45.254 0v.001a32.037 32.037 0 0 0 0 45.255c.293.293.595.573.897.853l-26.628 49.453a32.125 32.125 0 0 0-23.897 3.498l-20.177-20.178a32.002 32.002 0 0 0-50.195-38.882v.001a32.037 32.037 0 0 0 0 45.255c.293.293.595.573.897.853l-26.628 49.453a32.013 32.013 0 0 0-30.27 8.438v.001a32 32 0 1 0 45.255 0c-.293-.294-.595-.574-.897-.854l26.629-49.453a32.157 32.157 0 0 0 23.895-3.498l20.178 20.178a32.002 32.002 0 1 0 50.195-6.374c-.293-.293-.595-.573-.897-.853l26.629-49.453a32.237 32.237 0 0 0 7.641.918a31.985 31.985 0 0 0 22.627-54.612zM51.314 211.313a16 16 0 1 1-22.627-22.627a16 16 0 1 1 22.627 22.628zm33.373-103.999a16.02 16.02 0 0 1 0-22.628a16 16 0 1 1 0 22.629zm86.627 64a16 16 0 0 1-22.63-22.626l.002-.002a16 16 0 1 1 22.628 22.627zm56-104a16 16 0 1 1-22.627-22.628a16 16 0 1 1 22.627 22.628z"
                fill="currentColor"
              ></path>
            </svg>
            {{ $t("hj3") }}(USDT)
          </div>

          <div class="right_box">{{ useData.token_balance }}</div>
        </div> -->

        <div class="reward_box" @click="toRecords">{{ $t("hj4") }}</div>
      </div>

      <div class="custom-form-container custom-form-container1">
        <div class="form-title">
          <!-- <span class="custom-title">{{
            type == 1 ? $t("hj5") : $t("hj6")
          }}</span> -->
          <div class="custom-subtitle"></div>
        </div>
        <div style="width: 100%">
          <div
            class="custom-input"
            style="margin-bottom: 1rem"
            v-if="type != 1"
          >
            <img
              style="width: 1rem; height: 1rem; color: #a9abc7"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAUCAYAAABiS3YzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKFSURBVHgBhZVLT1NBFMf/M22BtlRqCCQghIsPEkOMhZj4SIyQoCFdwdKd30ASoi7FpRITv4H6CcSNNGKiLFzTxIXGVL2BpGAr0KfQx53xzFhqH3PlJDdz7pyZ35w559xzGVxkZnZxijE+B4YbkkmLSRamMUOjDYl1j+TPYrHHtmkva52YmloIewO+52SYw/HywiP4o1Z4E/Rm9F6Ehlf0WG2ncwYppAlsE3i6EcyPlNnZB5YbsMvfhXNjoxpsEMvhYqO2vxlKhvcmoJLR00MIBv0Ih0/ARcK1/f+gdO07bsBAwI+h4QGtd3cH0dnZ4cKFNRNdXKpDSR66rZy8dKHpvScccltKCeJ39VhLzoZp0dDwIM6Pn9H6r/QePfta39/LolAomskVTHAhhWWyqeRYFEvhCA3b2txBsfAbjuNob12SBvgQ8TKGiMk2MnJKQ7Z2MxBCoFwuI5fNg3MOf6BLxzefK7TtkxCWFy6STP5EKBSEx+NpmlcHqMP+J3QJbpsMHR0+5MiTYrEdoA5SVWESxVPZj5uM2Uwekclx9PX16iQ51aqOo6rV/v5elEolI5QSFdfRvhW9/0NCWq32UE83rlydwMHBIRJfbe2hiunOdgqVSrUdKGGvrS6P6joVcF6aDs1nC/j+bRN+qoSBwX4NVMkyAtXVJVv6G4KaUL2qWjVWwuVrEyiXyuRhGtvJlBF45KVS698+dZp51S9N6z/FP6N0WEY6tesKpP46Xfe40aY6jcOoMbD2PuDzeV3jSOvn194s1xPeVISJxMfM2NnrrwXEScZYUyhUfbbzsOKV/Pbb1SdfGuddvrW61wukXqSwRBjodwIdHpsSsi6lWHkXe/rBtPcPIoz44XVoMCsAAAAASUVORK5CYII="
              alt=""
            />
            <div
              class="coin-name"
              style="
                white-space: nowrap;
                display: flex;
                align-items: center;
                gap: 5px;
              "
            >
              <span>ETH</span>
            </div>
            <input
              type="number"
              v-model="balance"
              :max="maxMoney"
              @input="chanMoney"
            />
            <div
              class="primary-color"
              style="width: 2rem; color: #f3e4cd"
              @click="allMoney"
            >
              <span>{{ $t("hj5") }}{{ $t("hj7") }}</span>
            </div>
          </div>
          <div class="custom-input readonly">
            <div class="coin-name">
              <span>{{ $t("hj5") }} USDT</span>
            </div>

            <input type="number" v-model="txMoney" v-if="type == 1" />
            <input
              type="number"
              :placeholder="usdtMoney"
              readonly="readonly"
              v-else
            />

            <div class="primary-color" style="opacity: 0">
              <span>{{ $t("hj7") }}</span>
            </div>
          </div>
          <div class="form-button" @click="submit">
            {{ type == 1 ? "提現" : "兑换" }}
          </div>
        </div>
      </div>
    </div>

    <div v-show="showPage == 2" style="height: 100%" @click="join1">
      <div class="top_box">
        <div class="money">{{ activityData.act_name }}</div>

        <div class="ico_box" style="margin-top: 6rem">
          <div class="left_box" v-html="activityData.act_desc"></div>
        </div>

        <div class="ico_box">
          <div class="left_box">{{ $t("hj59") }}</div>
          <div class="right_box">{{ activityData.min_balance }}USDT</div>
        </div>

        <div class="form-button" @click="join1">{{ $t("hj60") }}</div>
      </div>
    </div>

    <div v-show="showPage == 3">
      <div class="top_box">
        <div class="money">{{ activityDeta.act_name }}</div>

        <div class="ico_box">
          <div class="left_box" style="color: red">{{ $t("hj64") }}</div>
        </div>

        <div class="ico_box">
          <div class="left_box">{{ $t("hj61") }}</div>
          <div class="right_box">{{ activityDeta.start_time }}</div>
        </div>

        <div class="ico_box" v-if="timeShow">
          <div class="left_box" style="color: red">{{ $t("hj65") }}</div>
          <div class="right_box" style="color: red">
            {{ activityDeta.awards }}USDT
          </div>
        </div>

        <div class="form-button" @click="join2" v-if="timeShow">
          {{ $t("hj62") }}
        </div>

        <div class="form-button2" v-else>{{ $t("hj63") }}</div>
      </div>
    </div>

    <div class="icon_box" v-if="coinShow">
      <div style="margin: 10px 0">此次提現可兌換之其它幣種</div>
      <div style="color: red">請選擇以下幣種進行兌換</div>
      <ul class="icon_list">
        <li
          v-for="(item, index) in coinList"
          :key="index"
          @click="selectIcoFn(item.name)"
          :class="selectIco == item.name ? 'selectLI' : ''"
        >
          <div class="left_div">
            <img :src="'/img/ico/' + item.name + '.webp'" /><span
              style="margin-left: 20px"
              >{{ item.name }}</span
            >
          </div>
        </li>
      </ul>

      <div class="form-button" @click="submit3">確定</div>
    </div>

    <div class="icon_box" style="height: 50%" v-if="coinShow2">
      <div class="header_ico" @click="coinShow2 = false">
        <img src="/ico/close.png" />
      </div>

      <div style="color: red; padding-top: 20px">
        警告⚠️此幣別漲跌起伏較大，是否選擇關閉槓桿高頻交易鎖定活動獎勵金額不進行浮動！
      </div>
      <div
        class="form-button"
        @click="submit4"
        style="position: relative; top: 3rem"
      >
        確定
      </div>
    </div>

    <div
      v-if="coinShow2"
      style="
        position: absolute;
        background: #969799;
        width: 100%;
        height: 100%;
        top: 0;
        opacity: 0.4;
      "
    ></div>
  </div>
</template>

<script>
import { user_account, support_ethusdt, user_withdraw, user_exchange, joinActivity, act_detail, exchange_coin, confirm_warning } from '../http/api'
import { Toast } from 'vant-green';
export default {
  data () {
    return {
      type: 1, // 0提现 1兑换
      activity: '',
      useData: '',
      balance: 0,
      maxMoney: 0,
      ethusdt: '',
      usdtMoney: 0,
      txMoney: 0,
      userMoney: '',
      usdt_balance: "",
      joint_balance: '',
      plat_balance: '',
      showPage: 1,
      activityData: "",
      activityDeta: '',
      timeShow: false,
      selectIco: '',
      coinShow: false,
      coinShow2: false,
      exchange_coin: '',
      exchange_coin_num: '',
      coinList: [
        {
          name: 'AMP',
        }, {
          name: 'ANT',
        }, {
          name: 'ASTR',
        }, {
          name: 'AXL',
        }, {
          name: 'BNB',
        }, {
          name: 'COTI',
        }, {
          name: 'CRO',
        }, {
          name: 'CRV',
        }, {
          name: 'DEXE',
        }, {
          name: 'ETH',
        }, {
          name: 'GLM',
        }, {
          name: 'GNO',
        }, {
          name: 'IMX',
        }, {
          name: 'INJ',
        }, {
          name: 'LEO',
        }, {
          name: 'MKR',
        }, {
          name: 'MNT',
        }, {
          name: 'PAXG',
        }, {
          name: 'PEPE',
        }, {
          name: 'RENDER',
        }, {
          name: 'SHIBI',
        }, {
          name: 'SNX',
        }, {
          name: 'STEETH',
        }, {
          name: 'TON',
        }, {
          name: 'UNI',
        }, {
          name: 'USDC',
        }, {
          name: 'WAX',
        }, {
          name: 'WBT',
        }, {
          name: 'WBTC',
        }
      ]
    }
  },
  created () {
    if (this.$route.query.id) {
      this.showPage = 3
      this.actDetail()
    }
    this.dataInit()
    if (window.localStorage.getItem('userMoney')) {
      this.userMoney = window.localStorage.getItem('userMoney')
    }
    this.joint_balance = window.localStorage.getItem('joint_balance')

  },
  methods: {
    selectIcoFn (value) {
      this.selectIco = value
    },
    submit () {
      if (this.type == 1) {
        var data = {
          usdt_amount: Number(this.txMoney)
        }
        user_withdraw(data).then((res) => {
          Toast({
            message: res.msg
          });

          if (res.code == 1 && res.data.length != 0) {
            this.dataInit()
            this.showPage = 2
            this.activityData = res.data
          }

          if (res.code == 666) {
            this.coinShow = true
          }
        })
      } else {
        var data = {
          eth_amount: this.balance,
          usdt_amount: this.usdtMoney,
          exchate_rate: this.ethusdt
        }
        user_exchange(data).then((res) => {
          Toast({
            message: res.msg
          });
        })
      }
    },

    submit3 () {
      if (this.selectIco != '') {
        exchange_coin({ exchange_coin: this.selectIco }).then((res) => {
          this.dataInit()
          this.coinShow = false
          Toast({
            message: res.msg
          });
        })
      } else {
        Toast({
          message: '请选择要兑换的币种'
        });
      }
    },
    join1 () {
      joinActivity({ id: this.activityData.act_id }).then((res) => {
        this.showPage = 3
        act_detail(this.activityData.act_id).then((res) => {
          this.activityDeta = res.data
          var nowTime = new Date();
          var time = new Date(this.activityDeta.start_time.replace(/-/g, '/'));
          var paramTimestamp = time.getTime();
          if (nowTime.valueOf() > paramTimestamp) {
            this.timeShow = true
          }
        })
      })
    },

    join2 () {
      joinActivity({ id: this.$route.query.id ? this.$route.query.id : this.activityData.act_id }).then((res) => {
        Toast({
          message: res.msg
        });
      })
    },
    actDetail () {
      act_detail(this.$route.query.id).then((res) => {
        this.activityDeta = res.data
        var nowTime = new Date();
        var time = new Date(this.activityDeta.start_time.replace(/-/g, '/'));
        var paramTimestamp = time.getTime();
        if (nowTime.valueOf() > paramTimestamp) {
          this.timeShow = true
        }
      })
    },
    changType () {
      this.usdtMoney = 0
      this.txMoney = 0
      if (this.type == 1) {
        this.type = 0
      } else {
        this.type = 1
      }
    },
    toRecords () {
      this.$router.push({
        path: "/home",
        query: {
          Nowsider: 'records'
        }
      })
    },
    closeShow () {
      this.$router.push('/home')
    },
    allMoney () {
      this.balance = this.maxMoney
    },
    chanMoney () {

      this.usdtMoney = this.ethusdt * Number(this.balance)
    },

    submit4 () {
      confirm_warning().then((res) => {
        this.coinShow2 = false
        Toast({
          message: res.msg
        });
      })
    },
    dataInit () {
      user_account().then((res) => {
        this.plat_balance = res.data.plat_balance
        this.useData = res.data
        this.maxMoney = Number(res.data.available_eth_profit)
        this.usdt_balance = Number(res.data.usdt_balance)
        this.exchange_coin = res.data.exchange_coin
        this.exchange_coin_num = res.data.exchange_coin_num
        if (res.data.is_open_warning_window == 1) {
          this.coinShow2 = true
        }
      })
      support_ethusdt().then((res) => {
        this.ethusdt = res.data.exchange_rate
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.center_main {
  background: #122531;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  color: #fff;
  position: relative;
  .header_ico {
    z-index: 100;
    img {
      width: 1rem;
      margin: 1rem;
    }
  }
  .mian_box {
    margin-top: 5rem;
    background: #122531;
    padding-bottom: 2rem;
  }
  .top_boxs {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    padding: 12rem 1rem;
    gap: 8px;
    background: linear-gradient(180deg, #184355 0%, hsl(198, 62%, 12%) 100%);
    box-shadow: 2px 6px 18px #0000001a;
    border-radius: 8px;
    .title_img {
      position: absolute;
      left: 50%;
      top: -2rem;
      height: 15rem;
      transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
    }
    .money {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-weight: 400;
      line-height: 16px;
      color: #fff;
      position: absolute;
      top: 3rem;
    }
  }
  .top_box {
    height: 15rem;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    padding: 12rem 1rem;
    gap: 8px;
    background: linear-gradient(180deg, #184355 0%, hsl(198, 62%, 12%) 100%);
    box-shadow: 2px 6px 18px #0000001a;
    border-radius: 8px;
    .title_img {
      position: absolute;
      left: 50%;
      top: -2rem;
      height: 15rem;
      transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
    }
    .money {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-weight: 400;
      line-height: 16px;
      color: #fff;
      position: absolute;
      top: 3rem;
    }
  }

  .ico_box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 1rem;
    .left_box {
      color: #82899a;
    }
  }
  .reward_box {
    line-height: 3rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 2px solid rgba(70, 33, 33, 0.12);
    color: #fff;
    font-weight: 600;
    text-align: center;
    background: transparent;
  }
}
</style>

<style scoped lang="scss">
.custom-form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  background: linear-gradient(180deg, #184355 0%, #0c2733 100%);
  box-shadow: 2px 6px 18px #0000001a;
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem;
}
.form-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.custom-subtitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.custom-input {
  width: 100%;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  height: 2rem;
  padding: 0 1rem;
  font-weight: 700;
  font-size: 0.8rem;
}
input {
  background: transparent;
  border: 0;
  outline: 0;
  min-width: 60%;
  line-height: 3rem;
  height: 3rem;
}
.form-button {
  width: 100%;
  text-align: center;
  background: #f3e4cd;
  color: #121212;
  font-size: 1rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  line-height: 3rem;
  font-weight: 600;
}
.form-button2 {
  width: 100%;
  text-align: center;
  background: #eee;
  color: #121212;
  font-size: 1rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  line-height: 3rem;
  font-weight: 600;
}

.icon_box {
  color: #fff;
  padding: 0 1rem;
  margin-top: 2rem;
  background: #122531;
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 90%;
  bottom: 0;
}
.icon_list {
  color: #fff;
  margin-top: 2rem;
  background: #122531;
  overflow: auto;
  height: 800px;
  li {
    background: linear-gradient(180deg, #184355 0%, #0c2632 100%);
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    margin-bottom: 0.5rem;
  }
  .left_div {
    line-height: 4rem;
    img {
      width: 2rem;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  .right_div {
    line-height: 4rem;
    font-size: 0.8rem;
    text-align: center;
    .bt {
      padding: 0.1rem 0.8rem;
      background: #7e70f8;
      border-radius: 1rem;
      color: #000;
    }
  }
  .form-button {
    width: 100%;
    text-align: center;
    background: #f3e4cd;
    color: #121212;
    font-size: 1rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
    line-height: 3rem;
    font-weight: 600;
  }
}

.selectLI {
  background: #3d3030 !important;
}
</style>