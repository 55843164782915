<template>
  <div class="center_main" style="padding-top: 30px">
    <div class="center_main">
      <div class="title">{{ data.act_name }}</div>
      <div class="main_box">
        <div class="dede1" v-html="data.act_desc"></div>
      </div>

      <div class="bt" @click="join" style="text-align: center">
        {{ $t("hj24") }}
      </div>
    </div>

    <van-loading
      type="spinner"
      color="#1989fa"
      class="loading"
      v-show="loadingShow"
    />
  </div>
</template>

<script>
import { joinActivity, popup } from '../http/api'
export default {
  data () {
    return {
      data: [],
      loadingShow: true,
    }
  },
  props: {

  },
  created () {
    popup().then((res) => {
      this.data = res.data
      this.loadingShow = false
    })

  },
  mounted () {
  },
  methods: {
    closeShow () { },
    join () {
      joinActivity({ activityy_id: this.data.id }).then((res) => {
        this.$parent.close();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.center_main {
  background: #122531;
  height: 100%;
  width: 100%;
  z-index: 2;
  .title {
    line-height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 1rem;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 400;
    font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
      sans-serif;
  }
  .main_box {
    .dede1 {
      color: #fff;
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      text-align: center;
      padding: 1rem;
    }
  }
  .loading {
    text-align: center;
    margin-top: 20%;
  }

  .bt {
    width: 6rem;
    background: #ffc61c;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    color: #000;
    font-weight: 600;
    margin: 0 auto;
  }
  .header_ico img {
    width: 1rem;
    margin: 1rem;
  }
  .loading {
    text-align: center;
    height: 2rem;
    margin-top: -100%;
  }
}
</style>