/* eslint-disable */
export default {
  hj1: '残高',
  hj2: '総収益',
  hj3: 'チェーン残高',
  hj4: '賞を見る',
  hj5: '引き出し',
  hj6: '換算',
  hj7: 'すべて',
  hj8: '活動賞勵',
  hj9: '質押賞勵',
  hj10: 'キャッシュレコード',
  hj11: 'データが一時的にありません',
  hj12: '賞勵金額',
  hj13: '引き出し金額',
  hj14: 'ヘルプセンター',
  hj15: 'ガンダムを受け入れる',
  hj16: '賞勵',
  hj17: 'ルール＃ルール＃',
  hj18: '終了',
  hj19: '賞勵基準',
  hj20: 'ユーザー金額',
  hj21: '説明',
  hj22: '総賞勵',
  hj23: 'ユーザー賞勵',
  hj24: '活動に参加する',
  hj25: '受け取る',
  hj26: '質権抵当金額',
  hj27: '確認',
  hj28: 'キャンセル',
  hj31: '抵当に入れる',
  hj32: '抵当に入れる',
  hj33: '今行く',
  hj34: 'わかりました',
  hj35: '収益率',
  hj36: '質押額',
  hj37: '開始',
  hj38: 'アカウント',
  hj39: '年利率',
  hj40: '収益記録',
  hj41: 'ヘルプセンター',
  hj42: '質押を紹介する',
  hj43: 'アクティビティ',
  hj44: '言語の選択',
  hj45: 'カスタマーセンター',
  hj46: '言語の選択',
  hj47: '質押開始',
  hj48: '質押金額を入力してください',
  hj49: '質押',

  hj52: '送信',
  hj53: 'レプリケーション',

  hj54: '「リンクコード」',
  hj55: '「招待コードをコピーする」',
  hj56: '「USDTリンクコードを受け取る」',
  hj57: '「USDTを送信する」',
  hj58: '「USDT数量を入力」',
  hj59: '「最小需要金額」',
  hj60: '「参加確定」',
  hj61: '「アクティブロック時間」',
  hj62: '「利益資金の抽出」',
  hj63: '「ロック解除時間未満」',
  hj64: 'アクティビティインセンティブ（USDT）がバインドされている'
};
