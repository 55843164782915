<template>
  <div class="center_main">
    <div>
      <div class="img_box" v-if="activity.length == 0">
        <img src="/ico/zw.png" />
        <div>{{ $t("hj11") }}</div>
      </div>
      <li
        class="list3"
        v-for="(item, index) in activity"
        :key="index"
        style="color: #fff"
        @click="joinAct(item)"
      >
        <div class="flex_box">
          <div>{{ item.act_name }}</div>
          <div>{{ item.status_name }}</div>
        </div>
        <div>{{ item.end_time }}</div>
        <div class="flex_box">
          <div>{{ item.progress_name }}</div>
        </div>
      </li>
    </div>
  </div>
</template>

<script>

import { miningActivity, joinActivity } from '../http/api'
import { Toast } from 'vant-green';
export default {
  data () {
    return {
      nowIndex: 1,
      activity: [],
      activityShow: false,
    }
  },
  created () {
    this.dataInit()
  },
  methods: {
    toActive () {
      this.$router.push({
        path: "/activityDea",
        query: {
          id: value.id
        }
      })
    },
    dataInit () {
      miningActivity().then((res) => {
        this.activity = res.data
      })
    },
    joinAct (value) {
      if (value.status == '0') {
        joinActivity({ id: value.id }).then((res) => {
          this.activityShow = true
          Toast({
            message: res.msg
          });
          if (res.code !== 1001) {
            if (value.act_type == 4) {
              this.$router.push({
                path: "/use",
                query: {
                  id: value.id
                }
              })
            } else {
              this.$router.push({
                path: "/activityDea",
                query: {
                  id: value.id
                }
              })
            }
          }
        })
      } else {
        if (value.act_type == 4) {
          this.$router.push({
            path: "/use",
            query: {
              id: value.id
            }
          })
        } else {
          this.$router.push({
            path: "/activityDea",
            query: {
              id: value.id
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.center_main {
  background: #122531;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 20px 20px 0 0;
  z-index: 2;
  .img_box {
    margin-top: 2rem;
    text-align: center;
    color: #fff;
    img {
      width: 3rem;
    }
  }
  li {
    border: 1px solid rgb(204, 204, 204);
    margin: 0 1rem 20px 1rem;
    border-radius: 0.2rem;
    padding: 6px 10px;
  }
  .flex_box {
    display: flex;
    justify-content: space-between;
    line-height: 1.5rem;
  }
}
</style>