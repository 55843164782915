<template>
  <div class="main">
    <div v-show="!loadingShow">
      <h1>{{ pledgeData.title }}</h1>
      <div v-html="pledgeData.content"></div>
      <div class="bt" @click="siderShow">{{ $t("hj34") }}</div>
    </div>
    <van-loading
      type="spinner"
      color="#1989fa"
      class="loading"
      v-show="loadingShow"
    />
  </div>
</template>

<script>
import { staked_intro } from '../../http/api'
export default {
  data () {
    return {
      pledgeData: '',
      loadingShow: true
    }
  },
  created () {
    this.dataInit()
  },
  methods: {
    siderShow () {
      this.$parent.siderShow('');
    },
    dataInit () {
      staked_intro().then((res) => {
        this.loadingShow = false
        this.pledgeData = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  height: 95%;
  width: 100%;
  position: fixed;
  background: #000;
  top: 5%;
  border-radius: 10px 10px 0 0;
  text-align: center;
  color: #fff;
  padding: 0 1rem;
  .bt {
    width: 6rem;
    background: #ffc61c;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    color: #000;
    font-weight: 600;
    margin: 0 auto;
  }
  .loading {
    text-align: center;
    margin-top: 20%;
  }
}
</style>