<template>
  <div class="main">
    <div class="header_ico">
      <img src="/ico/close.png" @click="closeShow" />
      <div>质押</div>
    </div>

    <div style="margin-left: 0.5rem">{{ $t("hj47") }}</div>

    <ul class="main_box">
      <van-loading
        type="spinner"
        color="#1989fa"
        class="loading"
        v-show="loadingShow"
      />
      <li
        v-for="(item, index) in coinList"
        :key="index"
        @click="toDetail(item)"
      >
        <div class="left_div">
          <img :src="item.icon_url" />{{ item.coin_name }}
        </div>
        <div class="right_div" style="color: #21c021">
          <div>{{ $t("hj39") }}+{{ item.return_rate }}%</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { staked_coin } from '../../http/api'
export default {
  data () {
    return {
      coinList: [],
      loadingShow: true
    }
  },
  created () {
    this.dataInit()
  },
  methods: {
    closeShow () {
      this.$router.push("Home");
    },
    toDetail (item) {
      this.$router.push({
        path: "/pledgeDetail",
        query: {
          symbol: item.symbol,
          id: item.id,
          name: item.coin_name,
          icon_url: item.icon_url,
          coin_code: item.coin_code
        }
      })
    },
    dataInit () {
      staked_coin().then((res) => {
        this.loadingShow = false
        this.coinList = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  height: 100%;
  width: 100%;
  background: #000;
  border-radius: 10px 10px 0 0;
  color: #fff;
  .header_ico {
    display: flex;
    div {
      margin: 1rem;
      width: 100%;
      text-align: center;
    }
    img {
      position: absolute;
      width: 1rem;
      margin: 1rem;
    }
  }

  .main_box {
    color: #fff;
    padding: 0 1rem;
    margin-top: 2rem;
    li {
      background: #252524;
      border-radius: 1rem;
      display: flex;
      justify-content: space-between;
      padding: 0 1rem;
      margin-bottom: 0.5rem;
    }
    .left_div {
      line-height: 4rem;
      img {
        width: 2rem;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    .right_div {
      line-height: 4rem;
      font-size: 0.8rem;
      text-align: center;
      .bt {
        font-size: 0.6rem;
        padding: 0.05rem 0.8rem;
        background: #7e70f8;
        border-radius: 1rem;
        color: #000;
      }
    }
  }
  .loading {
    text-align: center;
    margin-top: 20%;
  }
}
</style>