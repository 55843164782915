<template>
  <div class="center_main">
    <div class="top_but">
      <div @click="changeIndex(1)" :class="nowIndex == 1 ? 'change' : ''">
        {{ $t("hj8") }}
      </div>
      <div @click="changeIndex(5)" :class="nowIndex == 5 ? 'change' : ''">
        {{ $t("hj9") }}
      </div>
      <div @click="changeIndex(3)" :class="nowIndex == 3 ? 'change' : ''">
        {{ $t("hj10") }}
      </div>
    </div>

    <div v-show="nowIndex == 1">
      <van-list
        v-model="loading"
        :finished="query.finished"
        finished-text="加载完成"
        loading-text="加载中"
        @load="onLoad"
        :immediate-check="false"
      >
        <li
          class="list3"
          v-for="(item, index) in awardData"
          :key="index"
          style="color: #fff"
        >
          <div>{{ item.updated_at }}</div>
          <div>{{ $t("hj12") }}:{{ item.usdt_amount }}</div>
        </li>
      </van-list>
    </div>

    <div v-show="nowIndex == 3">
      <van-list
        v-model="loading"
        :finished="withdraw.finished"
        finished-text="加载完成"
        loading-text="加载中"
        @load="onLoad3"
        :immediate-check="false"
      >
        <li
          class="list3"
          v-for="(item, index) in withdrawData"
          :key="index"
          style="color: #fff"
        >
          <div>{{ item.updated_at }}</div>
          <div>{{ item.status_name }}</div>
          <div>{{ $t("hj13") }}:{{ item.usdt_amount }}</div>
        </li>
      </van-list>
    </div>

    <div v-show="nowIndex == 5">
      <van-list
        v-model="loading"
        :finished="query2.finished"
        finished-text="加载完成"
        loading-text="加载中"
        @load="onLoad2"
        :immediate-check="false"
      >
        <li
          class="list3"
          v-for="(item, index) in awardDatas"
          :key="index"
          style="color: #fff"
        >
          <div>usdt:{{ item.usdt_amount }}</div>
          <div>{{ item.created_at }}</div>
        </li>
      </van-list>
    </div>
  </div>
</template>

<script>
import { award_log, withdraw_logs } from '../http/api'
export default {
  data () {
    return {
      nowIndex: 1,
      withdrawData: [], //3
      awardData: [],  //1
      awardDatas: [], //5
      loading: false,
      loadingShow: true,
      query: {
        op_type: 1,
        pageNum: 1,
        finished: false
      },
      query2: {
        op_type: 5,
        pageNum: 1,
        finished: false
      },
      withdraw: {
        pageNum: 1,
        finished: false
      }
    }
  },
  created () {
    award_log(this.query).then((res) => {
      this.loadingShow = false
      this.awardData = res.data.list
      this.finished = !res.data.hasMore
    })

    withdraw_logs(this.withdraw).then((res) => {
      this.finished = !res.data.hasMore
      this.withdrawData = res.data.list
      this.loadingShow = false
      this.withdraw.pageNum++;
    })

    award_log(this.query2).then((res) => {
      this.loadingShow = false
      this.awardDatas = res.data.list
      this.finished = !res.data.hasMore
    })
  },
  methods: {
    onLoad () {
      console.log('执行了', 1)
      this.dataOnLoad(this.nowIndex)
    },
    onLoad2 () {
      console.log('执行了', 2)
      this.dataOnLoad(this.nowIndex)
    },
    onLoad3 () {
      console.log('执行了', 3)
      this.dataOnLoad(this.nowIndex)
    },

    changeIndex (value) {
      this.loadingShow = true
      this.nowIndex = value
      // if (value == 1) {
      //   award_log(this.query).then((res) => {
      //     this.loadingShow = false
      //     this.awardData = res.data.list
      //     this.finished = !res.data.hasMore
      //   })
      // } else if (value == 5) {
      //   award_log(this.query2).then((res) => {
      //     this.loadingShow = false
      //     this.awardDatas = res.data.list
      //     this.finished = !res.data.hasMore
      //   })
      // } else {
      //   withdraw_logs(this.withdraw).then((res) => {
      //     this.finished = !res.data.hasMore
      //     this.withdrawData = res.data.list
      //     this.loadingShow = false
      //     this.withdraw.pageNum++;
      //   })
      // }
    },
    dataOnLoad (value) {
      this.loadingShow = true
      this.nowIndex = value
      this.query.op_type = value
      if (value == 1) {
        award_log(this.query).then((res) => {
          this.loadingShow = false
          this.awardData = res.data.list
          this.query.finished = !res.data.hasMore
          this.query.pageNum++;
        })
      } else if (value == 5) {
        award_log(this.query2).then((res) => {
          this.loadingShow = false
          this.awardDatas = res.data.list
          this.query.finished = !res.data.hasMore
          this.query.pageNum++;
        })
      } else {
        withdraw_logs(this.withdraw).then((res) => {
          this.withdraw.finished = !res.data.hasMore
          this.loadingShow = false
          this.withdraw.pageNum++;
          console.log(this.withdrawData, res.data.list)
          this.withdrawData = this.withdrawData.concat(res.data.list);
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.center_main {
  background: #122531;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 20px 20px 0 0;
  z-index: 2;
  overflow: auto;
  padding-bottom: 5rem;
  .top_but {
    padding: 1rem;
    display: flex;
    div {
      width: auto !important;
      padding: 0 10px;
      line-height: 2rem;
      background: linear-gradient(180deg, #184355 0%, #0c2733 100%);
      box-shadow: 2px 6px 18px #0000001a;
      color: #fff;
      border-radius: 10px;
      white-space: nowrap;
      margin-right: 0.5rem;
    }
    .change {
      background: #f3e4cd;
      color: #121212;
    }
  }
  .img_box {
    margin-top: 2rem;
    text-align: center;
    color: #fff;
    img {
      width: 3rem;
    }
  }
  li {
    border: 1px solid rgb(204, 204, 204);
    margin: 0 1rem 20px 1rem;
    border-radius: 0.5rem;
    padding: 6px 10px;
  }

  .loading {
    text-align: center;
    margin-top: 20%;
  }
}
</style>