import axios from './axios';

// 帮助中心
export function resetDictionaryStatus(params) {
  return axios({
    method: 'GET',
    url: 'mining/help',
    data: params
  });
}
// 奖励明细
export function award_log(params) {
  return axios({
    method: 'GET',
    url: 'user/award_log?op_type=' + params.op_type + '&page=' + params.pageNum
  });
}

// 提现记录
export function withdraw_logs(params) {
  return axios({
    method: 'GET',
    url: 'user/withdraw_logs?page=' + params.pageNum,
    data: params
  });
}

//质押介绍
export function staked_intro(params) {
  return axios({
    method: 'GET',
    url: 'mining/staked_intro',
    data: params
  });
}

//质押币种列表
export function staked_coin(params) {
  return axios({
    method: 'GET',
    url: 'mining/staked_coin',
    data: params
  });
}

//活动列表
export function miningActivity(params) {
  return axios({
    method: 'GET',
    url: 'mining/activity',
    data: params
  });
}

//参加活动
export function joinActivity(params) {
  return axios({
    method: 'POST',
    url: 'mining/join?activity_id=' + params.id
  });
}

//活动明细
export function act_detail(params) {
  return axios({
    method: 'GET',
    url: 'mining/act_detail?activity_id=' + params
  });
}

//账号明细
export function user_account(params) {
  return axios({
    method: 'GET',
    url: 'user/account'
  });
}

//获取汇率
export function support_ethusdt(params) {
  return axios({
    method: 'GET',
    url: 'support/ethusdt'
  });
}

//提現
export function user_withdraw(params) {
  return axios({
    method: 'POST',
    url: 'user/withdraw',
    data: params
  });
}

//兑换
export function user_exchange(params) {
  return axios({
    method: 'POST',
    url: 'user/exchange',
    data: params
  });
}

//实时行情
export function get_market(params) {
  return axios({
    method: 'GET',
    url: 'support/get_market?symbol=' + params
  });
}

//K线
export function getkdata(params) {
  return axios({
    method: 'GET',
    url:
      'support/getkdata?symbol=' + params.ethusdt + '&period=' + params.period
  });
}
// 质押币种介绍
export function staked_coin_intro(params) {
  return axios({
    method: 'GET',
    url: 'mining/staked_coin_intro?coin_id=' + params
  });
}

//质押
export function mining_stake(params) {
  return axios({
    method: 'POST',
    url: 'mining/stake',
    data: params
  });
}

// 获取总质押额
export function total_staked_amount(params) {
  return axios({
    method: 'GET',
    url: 'mining/total_staked_amount?coin_id=' + params
  });
}

// 活动弹窗
export function popup() {
  return axios({
    method: 'GET',
    url: 'mining/popup '
  });
}

// 获取邀请码
export function invitecode() {
  return axios({
    method: 'GET',
    url: 'user/invitecode '
  });
}

//兑换币种
export function exchange_coin(params) {
  return axios({
    method: 'POST',
    url: 'user/exchange_coin',
    data: params
  });
}

// 活动弹窗
export function confirm_warning() {
  return axios({
    method: 'POST',
    url: 'user/confirm_warning '
  });
}

//获取客服链接
export function getKf() {
  return axios({
    method: 'GET',
    url: 'support '
  });
}
