<template>
  <div class="center_main">
    <div class="mian_box">
      <div class="custom-form-container custom-form-container1">
        <div class="form-title">
          <!-- <span class="custom-title">{{
            type == 1 ? $t("hj5") : $t("hj6")
          }}</span> -->
          <div class="custom-subtitle"></div>
        </div>
        <div style="width: 100%">
          <div style="margin-bottom: 0.5rem">{{ $t("hj56") }}</div>
          <div class="custom-input readonly">
            <input type="number" v-model="invitecodes" />

            <div class="primary-color" style="opacity: 0"></div>
            <div class="coin-name" @click="copy">
              <span>{{ $t("hj53") }}</span>
            </div>
          </div>

          <div style="margin-top: 0.5rem">{{ $t("hj57") }}</div>
          <div class="custom-input readonly" style="margin-top: 0.5rem">
            <input type="number" v-model="writeCode" />

            <div class="primary-color" style="opacity: 0"></div>
            <div class="coin-name" @click="usdtShow = true">
              <span>{{ $t("hj52") }}</span>
            </div>
          </div>

          <div v-show="usdtShow">
            <div style="margin-top: 0.5rem">{{ $t("hj58") }}</div>
            <div class="custom-input readonly" style="margin-top: 0.5rem">
              <input type="number" v-model="usdtNumber" />

              <div class="primary-color" style="opacity: 0"></div>
              <div class="coin-name" @click="submit">
                <span>{{ $t("hj52") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { invitecode } from '../http/api'
import { Toast } from 'vant-green';
export default {
  data () {
    return {
      invitecodes: "",
      usdtNumber: '',
      usdtShow: false
    }
  },
  created () {
    this.dataInit()
  },
  methods: {
    dataInit () {
      invitecode().then((res) => {
        this.invitecodes = res.data.invite_code

      })
    },
    copy () {
      const textArea = document.createElement('textArea')
      textArea.value = this.invitecodes
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)
      Toast({
        message: '已复制邀请码'
      });
    },
    submit () {
      this.usdtNumber = ''
      this.usdtShow = false
      Toast({
        message: 'success'
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.center_main {
  background: #122531;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  color: #fff;
  position: relative;
  .header_ico {
    img {
      width: 1rem;
      margin: 1rem;
    }
  }
  .mian_box {
    background: #122531;
    padding-bottom: 2rem;
  }
  .top_box {
    height: 15rem;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    padding: 12rem 1rem;
    gap: 8px;
    background: linear-gradient(180deg, #184355 0%, #0c2733 100%);
    box-shadow: 2px 6px 18px #0000001a;
    border-radius: 8px;
    .title_img {
      position: absolute;
      left: 50%;
      top: -2rem;
      height: 15rem;
      transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
    }
    .money {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-weight: 400;
      line-height: 16px;
      color: #fff;
      position: absolute;
      top: 3rem;
    }
  }

  .ico_box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 1rem;
    .left_box {
      color: #82899a;
    }
  }
  .reward_box {
    line-height: 3rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 2px solid rgba(255, 255, 255, 0.12);
    color: #fff;
    font-weight: 600;
    text-align: center;
    background: transparent;
  }
}
</style>

<style scoped lang="scss">
.custom-form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  background: linear-gradient(180deg, #184355 0%, #0c2733 100%);
  box-shadow: 2px 6px 18px #0000001a;
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem;
}
.form-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.custom-subtitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.custom-input {
  width: 100%;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  height: 2rem;
  padding: 0 1rem;
  font-weight: 700;
  font-size: 0.8rem;
}
input {
  background: transparent;
  border: 0;
  outline: 0;
  min-width: 60%;
  line-height: 3rem;
  height: 3rem;
}
.form-button {
  width: 100%;
  text-align: center;
  background: #f3e4cd;
  color: #121212;
  font-size: 1rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  line-height: 3rem;
  font-weight: 600;
}
</style>