<template>
  <div class="main">
    <div class="header_ico">
      <img class="left_img" src="/ico/close.png" @click="closeShow" />
      <div class="centImg_box">
        <img class="cent_img" :src="$route.query.icon_url" />{{
          $route.query.name
        }}
      </div>
      <img class="right_img" src="/ico/bz.png" @click="helpShow" />
    </div>

    <div class="main_title">
      <div class="top_box">
        <div>{{ $route.query.coin_code }}</div>
        <div>{{ returnRate }}%</div>
      </div>
      <div class="bot_box">
        <div>
          <span style="margin-right: 5px">${{ close }}</span>
          <span :class="increase > 0 ? 'green' : 'red'"> {{ increase }}</span>
        </div>
        <div>{{ $t("hj35") }}</div>
      </div>
      <div id="echart"></div>
      <div class="dayFoter">
        <div
          @click="changeNowTime('60min')"
          :class="nowTime == '60min' ? 'selectTime' : ''"
        >
          60min
        </div>
        <div
          @click="changeNowTime('1day')"
          :class="nowTime == '1day' ? 'selectTime' : ''"
        >
          1D
        </div>
        <div
          @click="changeNowTime('1week')"
          :class="nowTime == '1week' ? 'selectTime' : ''"
        >
          1W
        </div>
        <div
          @click="changeNowTime('1mon')"
          :class="nowTime == '1mon' ? 'selectTime' : ''"
        >
          1M
        </div>
        <div
          @click="changeNowTime('1year')"
          :class="nowTime == '1year' ? 'selectTime' : ''"
        >
          1Y
        </div>
      </div>

      <div class="footer_ti">{{ $t("hj36") }}</div>
      <div class="money">$ {{ staked_amount }}</div>
      <div class="bt" @click="moneyShow">{{ $t("hj37") }}</div>
    </div>
    <div class="Masking" v-show="isMoney"></div>
    <pledge v-show="isHelp"></pledge>
    <money v-show="isMoney"></money>
  </div>
</template>

<script>
import pledge from "./help.vue";
import money from "./pledgeMoney.vue";
import * as echarts from 'echarts';
import { get_market, getkdata, total_staked_amount } from '../../http/api'
export default {
  data () {
    return {
      isHelp: false,
      isMoney: false,
      nowTime: '1day',
      kineData: [],
      staked_amount: '', // 总质押额
      returnRate: "", // 收益率
      close: '',// 收盘价
      increase: '', // 涨跌幅
    }
  },
  components: {
    pledge,
    money
  },
  created () {
    this.dataInit()
  },
  methods: {
    changeNowTime (value) {
      this.nowTime = value
      getkdata({ ethusdt: this.$route.query.symbol, period: this.nowTime }).then((res) => {
        this.kineData = res.data
        this.chartInit()
      })
    },
    closeShow () {
      this.$router.push("Home");
    },
    helpShow () {
      this.isHelp = !this.isHelp
    },
    moneyShow () {
      this.isMoney = !this.isMoney
      this.dataInit()
    },
    async dataInit () {
      await get_market((this.$route.query.symbol)).then((res) => {
        this.close = res.data.close
        this.increase = res.data.increase
      })
      await getkdata({ ethusdt: this.$route.query.symbol, period: this.nowTime }).then((res) => {
        this.kineData = res.data
        this.chartInit()
      })
      total_staked_amount((this.$route.query.id)).then((res) => {
        this.staked_amount = res.data.total_staked_amount
        this.returnRate = res.data.return_rate
      })
    },
    chartInit () {
      var chartDom = document.getElementById('echart');
      var myChart = echarts.init(chartDom);
      var option = {
        grid: {//位置
          left: '6%',
          right: 0,
          bottom: 20,
          top: 10,
          containLabel: true//是否包含坐标值
        },
        xAxis: [{
          type: 'time',
          axisLabel: {//坐标轴刻度标签
            show: false
          },
          axisLine: {//坐标轴轴线
            show: false
          },
          axisTick: {//坐标轴刻度
            show: false
          }
        }],
        yAxis: [{
          type: 'value',
          splitLine: {//坐标轴在grid区域中的分割线
            show: false
          },
          axisLabel: {//坐标轴刻度标签
            show: false
          },
        }],
        series: [{
          type: 'line',
          name: 'day',
          smooth: true,//是否平滑曲线
          symbol: 'none',//标记的图形，去掉默认的小圆点标识
          data: this.kineData,
          lineStyle: {//线条颜色
            color: '#344c23'
          },
        }]
      }
      option && myChart.setOption(option);
    }
  },
  mounted () {
    // this.chartInit()
  },


}
</script>

<style lang="scss" scoped>
.Masking {
  height: 50%;
  width: 100%;
  position: fixed;
  top: 0;
  background: #969799;
  opacity: 0.4;
}
.main {
  height: 100%;
  width: 100%;
  background: #000;
  border-radius: 10px 10px 0 0;
  color: #fff;
  .header_ico {
    height: 1rem;
    display: flex;
    div {
      width: 100%;
      text-align: center;
    }
    .left_img {
      position: absolute;
      width: 1rem;
      margin: 1rem;
      z-index: 2;
    }
    .right_img {
      position: absolute;
      width: 1rem;
      margin: 1rem;
      left: calc(90% - 1rem);
    }
    .centImg_box {
      position: absolute;
      padding-top: 1rem;
    }
    .cent_img {
      width: 1rem;
      margin-right: 10px;
    }
  }
  .main_title {
    margin-top: 3rem;
    padding: 0 1rem;
    .top_box {
      display: flex;
      justify-content: space-between;
    }
    .bot_box {
      margin-top: 0.5rem;
      display: flex;
      justify-content: space-between;
      font-size: 0.8rem;
    }
  }
  #echart {
    height: 400px;
  }
  .dayFoter {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    div {
      width: 2rem;
    }
  }
  .footer_ti {
    margin-top: 2rem;
  }
  .money {
    // font-weight: 900;
    font-size: 1.5rem;
    margin-top: 1rem;
  }
  .bt {
    text-align: center;
    width: 90%;
    margin: 0 auto;
    margin-top: 3rem;
    line-height: 2rem;
    background: #fff;
    border-radius: 1rem;
    color: #000;
  }
  .selectTime {
    color: #969799;
  }
  .green {
    color: green;
  }
  .red {
    color: red;
  }
}
</style>