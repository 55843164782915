<template>
  <div id="app">
    <router-view></router-view>
    <van-popup
      v-model="activityShow"
      style="height: 80%"
      closeable
      @closed="close"
    >
      <activity2 ref="activity"></activity2
    ></van-popup>
  </div>
</template>

<script>

import './assets/js/main.js'
import activity2 from './pages/activity2.vue'
import { popup } from './http/api'
export default {
  name: 'app',
  data () {
    return {
      status: 0,
      activityShow: false,
      interval: null,
    };
  },
  components: {
    activity2
  },
  methods: {
    close () {
      this.status = 1
    }
  },
  created () {
    if (this.interval == null) {
      setInterval(() => {
        if (this.status != 1 && this.activityShow == false) {
          this.interval = popup().then((res) => {
            if (res.data.length != 0 && this.status == 0) {
              this.activityShow = true
              clearInterval(this.interval)
            } else {
              this.activityShow = false
            }
          })
        }
      }, 5000)
    } else {
      clearInterval(this.interval)
    }
  }
}
</script>

<style>
html {
  height: 100%;
}
body {
  height: 100%;
}
#app {
  height: 100%;
}
body .van-toast {
  font-size: 38px;
  padding: 30px;
  line-height: 50px;
  width: 230px;
}
body .van-toast .van-toast__icon {
  font-size: 50px;
}
*,
:after,
:before {
  box-sizing: border-box;
}
.van-tab__text--ellipsis {
  overflow: visible !important;
}
li {
  list-style-type: none;
}
.van-popup {
  width: 80%;
}
</style>
