<template>
  <div class="main">
    <h1 style="text-align: center">{{ $t("hj26") }}</h1>

    <h5>{{ $t("hj26") }} ($)</h5>

    <van-field v-model="text" :placeholder="$t('hj48')" type="number" />

    <div class="bt" @click="siderShow">{{ $t("hj27") }}</div>
    <div class="bt bt2" @click="close">{{ $t("hj28") }}</div>
  </div>
</template>

<script>
import { mining_stake } from '../../http/api'
export default {
  data () {
    return {
      text: 0
    }
  },
  methods: {
    siderShow () {
      var data = {
        amount: this.text,
        coin_id: this.$route.query.id
      }
      mining_stake(data).then((res) => {
      })
      this.$parent.moneyShow();
    },
    close () {
      this.$parent.moneyShow();
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  height: 50%;
  width: 100%;
  position: fixed;
  background: #ed7878;
  top: 50%;
  border-radius: 10px 10px 0 0;
  color: #fff;
  padding: 0 1rem;
  .bt {
    text-align: center;
    width: 80%;
    background: #ffc61c;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    color: #000;
    font-weight: 600;
    margin: 1rem auto;
  }
  .bt2 {
    background: #fff;
  }
}
.van-cell {
  background: none;
  font-size: 1rem;
}
</style>

<style>
.van-field__label {
  width: 7rem !important;
}
.van-field__control {
  color: #646566;
}
</style>