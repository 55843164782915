/* eslint-disable */
export default {
  hj1: 'balance',
  hj2: 'Activity rewards',
  hj3: 'On chain balance',
  hj4: 'View rewards',
  hj5: 'Withdrawal',
  hj6: 'exchange',
  hj7: 'whole',
  hj8: 'Activity rewards',
  hj9: 'Pledge reward',
  hj10: 'Withdrawal records',
  hj11: 'There is currently no data available',
  hj12: 'Reward amount',
  hj13: 'Withdrawal amount',
  hj14: 'Help Center',
  hj15: 'You will receive up to',
  hj16: 'reward',
  hj17: 'rule',
  hj18: 'finish',
  hj19: 'Reward standards',
  hj20: 'User amount',
  hj21: 'explain',
  hj22: 'Total reward',
  hj23: 'User rewards',
  hj24: 'Participate in activities',
  hj25: 'receive',
  hj26: 'Pledge amount',
  hj27: 'confirm',
  hj28: 'cancel',
  hj31: 'pledge',
  hj32: 'Start pledging',
  hj33: 'Now proceed to',
  hj34: 'I got it',
  hj35: 'Yield',
  hj36: 'Pledged amount',
  hj37: 'start',
  hj38: 'account',
  hj39: 'Annualized interest rate',
  hj40: 'Revenue records',
  hj41: 'Help Center',
  hj42: 'Introduction to Pledge',
  hj43: 'activity',
  hj44: 'Choose language',
  hj45: 'Customer Service Center',
  hj46: 'Choose language',
  hj47: 'Start pledging',
  hj48: 'Please enter the pledge amount',
  hj49: 'pledge',
  hj50: 'no more',
  hj50: 'Successfully participated',
  hj51: 'determine',
  hj52: 'send out',
  hj53: 'copy',

  hj54: 'Link code',
  hj55: 'Copy invitation code',
  hj56: 'Accept USDT link code',
  hj57: 'Send USDT to',
  hj58: 'Enter USDT quantity',
  hj59: 'Minimum required amount',
  hj60: 'Confirm participation',
  hj61: 'Activity lock time',
  hj62: 'Withdraw profitable funds',
  hj63: 'Unlocking time not yet reached',
  hj64: 'The activity reward (USDT) has been bound'
};
